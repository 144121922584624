import './QuizFact.scss';
import { ReactComponent as Close } from "../../assets/images/X.svg";
import '../../assets/images/electric-fact.svg'

export default function QuizFact({
    headText,
    infoText,
    infoImg,
    setQuizPopupOn,
}) {
 
  const imgSrc = infoImg ? require(`../../assets/images/${infoImg}`) : "";
      
  return (
    <div className="quiz_fact_container">
      <div className="badge_logo_bright">
        <img src={imgSrc} alt="" />
      </div>
      {/* <Close className='quiz_fact_close' onClick={() => setQuizPopupOn(false)}/> */}
      <p className="head_text">{headText}</p>
      <p className="info_text">
            {infoText}
      </p>
    </div>
  );
}
