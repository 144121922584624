import { produce } from "immer";
import { IK_VEHICLE_INPUT_INDEX } from "../../constants/quizQuestionsIndex";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel";

export default function IkVehicleInput({ quizQuestions, setQuizQuestions }) {
  const questionObj = quizQuestions[IK_VEHICLE_INPUT_INDEX];

  function editVehicleInfo(text) {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const vehicleInfoQuesObj = quizQuestionsDraft[IK_VEHICLE_INPUT_INDEX];
        vehicleInfoQuesObj.currSelected = [text];
        vehicleInfoQuesObj.vehicleInfo = text;
    }));
  }

  return (
    <div className="container">
      <FormFieldWithLabel
          label="Vehicle (Year, Make and Model)"
          type="text"
          id="vehicle"
          placeholder=""
          value={questionObj.vehicleInfo}
          onChange={(e) => {
            editVehicleInfo(e.target.value)
          }}
          errorMessage={""}
        />
    </div>
  );
}
