import './UserDetailsForm.scss';
import { useState, useEffect } from "react";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel.jsx";
import { produce } from "immer";
import { USER_DETAILS_INDEX } from "../../constants/quizQuestionsIndex.js";

export default function UserDetailsForm({ quizQuestions, setQuizQuestions }) {
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError("Invalid Email");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePhoneNo = (phoneNo) => {
    const phoneNoRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (phoneNo && !phoneNoRegex.test(phoneNo)) {
      setPhoneNoError("Invalid phone number");
      return false;
    }
    setPhoneNoError("");
    return true;
  };

  return (
    <>
      <div className="combine-fields">
        <FormFieldWithLabel
          label="First Name"
          type="text"
          id="first-name"
          placeholder="John"
          value={quizQuestions[USER_DETAILS_INDEX].firstName}
          onChange={(e) => {
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[USER_DETAILS_INDEX].firstName =
                  e.target.value;
              })
            );
          }}
          errorMessage={""}
        />
        <FormFieldWithLabel
          label="Last Name"
          type="text"
          id="last-name"
          placeholder="Terry"
          value={quizQuestions[USER_DETAILS_INDEX].lastName}
          onChange={(e) => {
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[USER_DETAILS_INDEX].lastName =
                  e.target.value;
              })
            );
          }}
          errorMessage={""}
        />
      </div>
        <FormFieldWithLabel
          label="Email"
          type="text"
          id="email"
          placeholder="johnterry@hotmail.com"
          value={email}
          onChange={(e) => {
            if (validateEmail(e.target.value)) {
              setQuizQuestions(
                produce((quizQuestionsDraft) => {
                  quizQuestionsDraft[USER_DETAILS_INDEX].email = e.target.value;
                })
              );
            }
            setEmail(e.target.value);
          }}
          errorMessage={emailError}
        />
        <FormFieldWithLabel
          label="Phone No."
          type="text"
          id="phone-no"
          placeholder="604 888 8888"
          value={phoneNo}
          onChange={(e) => {
            if (validatePhoneNo(e.target.value)) {
              setQuizQuestions(
                produce((quizQuestionsDraft) => {
                  quizQuestionsDraft[USER_DETAILS_INDEX].phoneNo =
                    e.target.value;
                })
              );
            }
            setPhoneNo(e.target.value);
          }}
          errorMessage={phoneNoError}
        />
      <div className="dropdown-group">
        <label htmlFor="contact-type">Preferred Method of Contact</label>
        <select
          value={quizQuestions[USER_DETAILS_INDEX].preferredMethodOfContact}
          onChange={(e) => {
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[
                  USER_DETAILS_INDEX
                ].preferredMethodOfContact = e.target.value;
              })
            );
          }}
          className="dropdown"
        >
          <option value="email">Email</option>
          <option value="phone">Phone</option>
        </select>
      </div>
  </>
  );
}
