import './RestartButton.scss';
import { useNavigate } from "react-router-dom";
import { ReactComponent as RestartButton } from "../../assets/icons/restart.svg";


export default function RestartButtonContainer({dealershipId}) {

    const navigate = useNavigate();

    function onRestartButton() {
        console.log("Clicked on Restart");
        navigate(`/${dealershipId}`);
      }

    return (
        <div className="restart" onClick={onRestartButton}>
            <span className="restart__button">
              <RestartButton />
            </span>
            <p className="restart__text">Restart</p>
        </div>
    );
}