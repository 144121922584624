export const DEALERSHIP_CONFIG = {
    "camclarkford":{
        name: "Cam Clark Ford",
        imgUrl: "https://ddztmb1ahc6o7.cloudfront.net/camclarkfordrichmond/wp-content/uploads/2024/04/12160321/Cam-Clark-Ford-PNG.png",
        linkMatchesToVDP: true,
        defaultInventoryImage: false
    },
    "trial":{
        name: "Your Dealership Name",
        imgUrl: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(2)+(1).png",
        linkMatchesToVDP: false,
        defaultInventoryImage: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(3).png"
    }
}