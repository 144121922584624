import "./Gallery.scss";
import { useState, useEffect } from "react";
import DashboardGallery from "../../components/DashboardGallery/DashboardGallery";
import MaeOfferBanner from "../../components/MaeBanner/MaeBanner";

function Gallery() {
  const [openedThroughEmail,setOpenedThroughEmail] = useState(null);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setOpenedThroughEmail(params.get("email"));
  },[])
  return (
    <>
    <div className="gallery-page">
      <DashboardGallery />
    </div>
    {openedThroughEmail === "1" && <MaeOfferBanner />}
    </>
  );
}

export default Gallery;
