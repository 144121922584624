import "./DashboardGallery.scss";
import LikedList from "../LikedList/LikedList";

function DashboardGallery() {

  return (
    <LikedList />
  );
}

export default DashboardGallery;
