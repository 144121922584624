import { FEATURE_SELECT_INDEX,  VALUE_RANKING_INDEX, VEHICLE_ENGINE_INDEX } from "../constants/quizQuestionsIndex";

export const QuizFactsInfo = {
  [VEHICLE_ENGINE_INDEX] : {
     headText : "Did You Know...",
     infoText : "BC Government incentives is currently offering up to $9,000 on eligible new Plug-In Hybrid or Full Electric vehicle!",
     img : "electric-fact.svg"
  },
  [FEATURE_SELECT_INDEX] : {
    headText : "Did You Know?",
    infoText : "As of 2018, all New cars sold  in Canada must have a Back-up Camera installed. Same thing for Automatic Headlights & Taillights in 2021.",
    img : "teacher-explaining.svg"
  },
  [VALUE_RANKING_INDEX] : {
    headText : "Reminder to Think Ahead",
    infoText : "We always recommend thinking ahead about your lifestyle in the next 5-10 years when considering your ranking. And a reminder to be thoughtful about upgrades as resale value is another important consideration for you at this time!",
    img : "woman-driving.svg"
  },

}