import './NoMatches.scss';
import {ReactComponent as NoMatchesFrame} from '../../assets/images/NoMatchesFrame.svg';
import RestartButtonContainer from '../RestartButton/RestartButton';
import { useDealershipContext } from '../../contexts/dealershipContext';

function NoMatches() {
  const {dealershipId} = useDealershipContext();

  return (
    <>
    {/* <div className='restart-btn-pos'>
      <RestartButtonContainer dealershipId={dealershipId}/>
    </div> */}
    <div className="page-no-match">
      <h2 className="page-no-match__header">Thank You</h2>
      <h1 className="page-no-match__subheader">You’re all checked-in!</h1>
      <p className="page-no-match__description">
      We’ve notified our concierge team of your preferences so they can serve you better. Thank you and we hope you enjoy your time at Cam Clark Ford Richmond!
      </p>
      <NoMatchesFrame className="page-no-match__filler"/>
    </div>
    </>
  );
}

export default NoMatches;
