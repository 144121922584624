export const MIN_BUDGET_VALUES = [
  "$10,000",
  "$20,000",
  "$30,000",
  "$40,000",
  "$50,000",
  "$60,000",
  "$70,000",
];

export const MAX_BUDGET_VALUES = [
  "$10,000",
  "$20,000",
  "$30,000",
  "$40,000",
  "$50,000",
  "$60,000",
  "$70,000",
  "$80,000+",
];

export const MONTHLY_BUDGET_VALUES = [
  {text : "Under $400", value : "$10,000 - $35,000" } ,
  {text : "$400 - $500", value : "$25,000 - $50,000"},
  {text : "$500 - $600", value : "$30,000 - $55,000"},
  {text : "$600 - $700", value : "$40,000 - $65,000"},
  {text : "$700 - $800", value : "$50,000 - $75,000"},
  {text : "$800 - $900", value : "$65,000 - $85,000"},
  {text : "$900 - $1000", value : "$80,000 - $105,000"},
  {text : "$1000+", value : "$95,000 - $200,000"},
];
