export const makesandmodels = {
  Chevrolet: {
    Equinox: { selected: false, dropDownIndex: null },
    Trailblazer: { selected: false, dropDownIndex: null },
    Malibu: { selected: false, dropDownIndex: null },
    Trax: { selected: false, dropDownIndex: null },
    Blazer: { selected: false, dropDownIndex: null },
    Corvette: { selected: false, dropDownIndex: null },
    Camaro: { selected: false, dropDownIndex: null },
    "Blazer EV": { selected: false, dropDownIndex: null },
    Suburban: { selected: false, dropDownIndex: null },
    Tahoe: { selected: false, dropDownIndex: null },
    Traverse: { selected: false, dropDownIndex: null },
  },
  Ford: {
    Mustang: { selected: false, dropDownIndex: null },
    Edge: { selected: false, dropDownIndex: null },
    Expedition: { selected: false, dropDownIndex: null },
    Bronco: { selected: false, dropDownIndex: null },
    "Bronco Sport": { selected: false, dropDownIndex: null },
    Escape: { selected: false, dropDownIndex: null },
    Explorer: { selected: false, dropDownIndex: null },
    "Mustang Mach-E": { selected: false, dropDownIndex: null },
  },
  GMC: {
    "HUMMER EV SUV": { selected: false, dropDownIndex: null },
    Terrain: { selected: false, dropDownIndex: null },
    Yukon: { selected: false, dropDownIndex: null },
    "Yukon XL": { selected: false, dropDownIndex: null },
  },
  Maserati: {
    GranTurismo: { selected: false, dropDownIndex: null },
    Quattroporte: { selected: false, dropDownIndex: null },
    Ghibli: { selected: false, dropDownIndex: null },
  },
  Cadillac: {
    LYRIQ: { selected: false, dropDownIndex: null },
    XT4: { selected: false, dropDownIndex: null },
    XT6: { selected: false, dropDownIndex: null },
    XT5: { selected: false, dropDownIndex: null },
    CT4: { selected: false, dropDownIndex: null },
    "CT4-V": { selected: false, dropDownIndex: null },
    CT5: { selected: false, dropDownIndex: null },
    "CT5-V": { selected: false, dropDownIndex: null },
    Escalade: { selected: false, dropDownIndex: null },
    "Escalade ESV": { selected: false, dropDownIndex: null },
  },
  BMW: {
    M8: { selected: false, dropDownIndex: null },
    "8 Series": { selected: false, dropDownIndex: null },
    M4: { selected: false, dropDownIndex: null },
    "4 Series": { selected: false, dropDownIndex: null },
    X5: { selected: false, dropDownIndex: null },
    X6: { selected: false, dropDownIndex: null },
    iX: { selected: false, dropDownIndex: null },
    M3: { selected: false, dropDownIndex: null },
    "X5 M": { selected: false, dropDownIndex: null },
    "X6 M": { selected: false, dropDownIndex: null },
    X7: { selected: false, dropDownIndex: null },
    XM: { selected: false, dropDownIndex: null },
    i7: { selected: false, dropDownIndex: null },
    "7 Series": { selected: false, dropDownIndex: null },
    i4: { selected: false, dropDownIndex: null },
    "5 Series": { selected: false, dropDownIndex: null },
    i5: { selected: false, dropDownIndex: null },
    "3 Series": { selected: false, dropDownIndex: null },
    Z4: { selected: false, dropDownIndex: null },
    "X3 M": { selected: false, dropDownIndex: null },
    "X4 M": { selected: false, dropDownIndex: null },
    "2 Series": { selected: false, dropDownIndex: null },
    M2: { selected: false, dropDownIndex: null },
    X1: { selected: false, dropDownIndex: null },
    X4: { selected: false, dropDownIndex: null },
    X3: { selected: false, dropDownIndex: null },
    X2: { selected: false, dropDownIndex: null },
  },
  "Alfa Romeo": {
    Tonale: { selected: false, dropDownIndex: null },
    Stelvio: { selected: false, dropDownIndex: null },
    Giulia: { selected: false, dropDownIndex: null },
  },
  Audi: {
    "Q8 e-tron": { selected: false, dropDownIndex: null },
    "Q8 e-tron Sportback": { selected: false, dropDownIndex: null },
    Q3: { selected: false, dropDownIndex: null },
    "A6 Sedan": { selected: false, dropDownIndex: null },
    "A6 allroad": { selected: false, dropDownIndex: null },
    "S6 Sedan": { selected: false, dropDownIndex: null },
    A8: { selected: false, dropDownIndex: null },
    S8: { selected: false, dropDownIndex: null },
    "RS 6 Avant": { selected: false, dropDownIndex: null },
    "S4 Sedan": { selected: false, dropDownIndex: null },
    "Q4 e-tron": { selected: false, dropDownIndex: null },
    "Q4 e-tron Sportback": { selected: false, dropDownIndex: null },
    "e-tron GT": { selected: false, dropDownIndex: null },
    "A7 Sportback": { selected: false, dropDownIndex: null },
    "S5 Cabriolet": { selected: false, dropDownIndex: null },
    "S5 Coupe": { selected: false, dropDownIndex: null },
    "S5 Sportback": { selected: false, dropDownIndex: null },
    "A5 Cabriolet": { selected: false, dropDownIndex: null },
    "A5 Coupe": { selected: false, dropDownIndex: null },
    "A5 Sportback": { selected: false, dropDownIndex: null },
    "A4 Sedan": { selected: false, dropDownIndex: null },
    "RS e-tron GT": { selected: false, dropDownIndex: null },
    "S3 Sedan": { selected: false, dropDownIndex: null },
    "RS 5 Coupe": { selected: false, dropDownIndex: null },
    "RS 5 Sportback": { selected: false, dropDownIndex: null },
    "S7 Sportback": { selected: false, dropDownIndex: null },
    "A3 Sedan": { selected: false, dropDownIndex: null },
    Q7: { selected: false, dropDownIndex: null },
    Q5: { selected: false, dropDownIndex: null },
    "Q5 Sportback": { selected: false, dropDownIndex: null },
    "RS 7": { selected: false, dropDownIndex: null },
    SQ5: { selected: false, dropDownIndex: null },
    "SQ5 Sportback": { selected: false, dropDownIndex: null },
    "A4 allroad": { selected: false, dropDownIndex: null },
    "RS Q8": { selected: false, dropDownIndex: null },
    SQ7: { selected: false, dropDownIndex: null },
    "SQ8 e-tron": { selected: false, dropDownIndex: null },
    "SQ8 e-tron Sportback": { selected: false, dropDownIndex: null },
    SQ8: { selected: false, dropDownIndex: null },
    Q8: { selected: false, dropDownIndex: null },
  },
  Mazda: {
    "CX-5": { selected: false, dropDownIndex: null },
    "CX-90 PHEV": { selected: false, dropDownIndex: null },
    "CX-90 MHEV": { selected: false, dropDownIndex: null },
    Mazda3: { selected: false, dropDownIndex: null },
    "CX-50": { selected: false, dropDownIndex: null },
    "Mazda3 Sport": { selected: false, dropDownIndex: null },
    "CX-30": { selected: false, dropDownIndex: null },
    "MX-5": { selected: false, dropDownIndex: null },
    "MX-5 RF": { selected: false, dropDownIndex: null },
    "MX-30 EV": { selected: false, dropDownIndex: null },
  },
  Subaru: {
    Crosstrek: { selected: false, dropDownIndex: null },
    Outback: { selected: false, dropDownIndex: null },
    Legacy: { selected: false, dropDownIndex: null },
    Impreza: { selected: false, dropDownIndex: null },
    Ascent: { selected: false, dropDownIndex: null },
    Forester: { selected: false, dropDownIndex: null },
  },
  Toyota: {
    Sequoia: { selected: false, dropDownIndex: null },
    Camry: { selected: false, dropDownIndex: null },
    "GR Supra": { selected: false, dropDownIndex: null },
    Corolla: { selected: false, dropDownIndex: null },
    "Corolla Hatchback": { selected: false, dropDownIndex: null },
    "Grand Highlander": { selected: false, dropDownIndex: null },
    Sienna: { selected: false, dropDownIndex: null },
    Prius: { selected: false, dropDownIndex: null },
    "Prius Prime": { selected: false, dropDownIndex: null },
    "GR Corolla": { selected: false, dropDownIndex: null },
    RAV4: { selected: false, dropDownIndex: null },
    "4Runner": { selected: false, dropDownIndex: null },
    Crown: { selected: false, dropDownIndex: null },
    Highlander: { selected: false, dropDownIndex: null },
    "Corolla Cross": { selected: false, dropDownIndex: null },
    Venza: { selected: false, dropDownIndex: null },
    GR86: { selected: false, dropDownIndex: null },
    bZ4X: { selected: false, dropDownIndex: null },
    "RAV4 Prime": { selected: false, dropDownIndex: null },
    "Land Cruiser": { selected: false, dropDownIndex: null },
  },
  Buick: {
    "Encore GX": { selected: false, dropDownIndex: null },
    Enclave: { selected: false, dropDownIndex: null },
    Envista: { selected: false, dropDownIndex: null },
    Envision: { selected: false, dropDownIndex: null },
  },
  MINI: {
    Countryman: { selected: false, dropDownIndex: null },
    "5 Door": { selected: false, dropDownIndex: null },
    "3 Door": { selected: false, dropDownIndex: null },
    Convertible: { selected: false, dropDownIndex: null },
  },
  Volkswagen: {
    Atlas: { selected: false, dropDownIndex: null },
    "Atlas Cross Sport": { selected: false, dropDownIndex: null },
    Jetta: { selected: false, dropDownIndex: null },
    Taos: { selected: false, dropDownIndex: null },
    Tiguan: { selected: false, dropDownIndex: null },
    "Golf R": { selected: false, dropDownIndex: null },
    "Jetta GLI": { selected: false, dropDownIndex: null },
    "Golf GTI": { selected: false, dropDownIndex: null },
    "ID.4": { selected: false, dropDownIndex: null },
  },
  Lexus: {
    NX: { selected: false, dropDownIndex: null },
    UX: { selected: false, dropDownIndex: null },
    LC: { selected: false, dropDownIndex: null },
    TX: { selected: false, dropDownIndex: null },
    RX: { selected: false, dropDownIndex: null },
    ES: { selected: false, dropDownIndex: null },
    RC: { selected: false, dropDownIndex: null },
    LX: { selected: false, dropDownIndex: null },
    "RC F": { selected: false, dropDownIndex: null },
    IS: { selected: false, dropDownIndex: null },
    LS: { selected: false, dropDownIndex: null },
    RZ: { selected: false, dropDownIndex: null },
    GX: { selected: false, dropDownIndex: null },
  },
  Kia: {
    Seltos: { selected: false, dropDownIndex: null },
    Carnival: { selected: false, dropDownIndex: null },
    Soul: { selected: false, dropDownIndex: null },
    "Niro EV": { selected: false, dropDownIndex: null },
    Telluride: { selected: false, dropDownIndex: null },
    K5: { selected: false, dropDownIndex: null },
    Niro: { selected: false, dropDownIndex: null },
    "Niro Plug-In Hybrid": { selected: false, dropDownIndex: null },
    Sportage: { selected: false, dropDownIndex: null },
    EV6: { selected: false, dropDownIndex: null },
    "Sportage Plug-In Hybrid": { selected: false, dropDownIndex: null },
    Forte: { selected: false, dropDownIndex: null },
    Forte5: { selected: false, dropDownIndex: null },
    "Sportage Hybrid": { selected: false, dropDownIndex: null },
    EV9: { selected: false, dropDownIndex: null },
    "Sorento Plug-In Hybrid": { selected: false, dropDownIndex: null },
    "Sorento Hybrid": { selected: false, dropDownIndex: null },
  },
  Honda: {
    "HR-V": { selected: false, dropDownIndex: null },
    "CR-V": { selected: false, dropDownIndex: null },
    "CR-V Hybrid": { selected: false, dropDownIndex: null },
    "Civic Sedan": { selected: false, dropDownIndex: null },
    "Civic Si Sedan": { selected: false, dropDownIndex: null },
    Odyssey: { selected: false, dropDownIndex: null },
    "Civic Hatchback": { selected: false, dropDownIndex: null },
    Pilot: { selected: false, dropDownIndex: null },
    "Accord Sedan": { selected: false, dropDownIndex: null },
    "Accord Hybrid": { selected: false, dropDownIndex: null },
    Passport: { selected: false, dropDownIndex: null },
    "Civic Type R": { selected: false, dropDownIndex: null },
  },
  "Land Rover": {
    "Range Rover Sport": { selected: false, dropDownIndex: null },
    "Range Rover Velar": { selected: false, dropDownIndex: null },
    Defender: { selected: false, dropDownIndex: null },
    Discovery: { selected: false, dropDownIndex: null },
    "Range Rover": { selected: false, dropDownIndex: null },
    "Discovery Sport": { selected: false, dropDownIndex: null },
    "Range Rover Evoque": { selected: false, dropDownIndex: null },
  },
  Dodge: {
    Hornet: { selected: false, dropDownIndex: null },
    Durango: { selected: false, dropDownIndex: null },
  },
  Acura: {
    Integra: { selected: false, dropDownIndex: null },
    MDX: { selected: false, dropDownIndex: null },
    RDX: { selected: false, dropDownIndex: null },
    TLX: { selected: false, dropDownIndex: null },
  },
  "Mercedes-Benz": {
    GLA: { selected: false, dropDownIndex: null },
    "C-Class": { selected: false, dropDownIndex: null },
    GLE: { selected: false, dropDownIndex: null },
    GLC: { selected: false, dropDownIndex: null },
    GLB: { selected: false, dropDownIndex: null },
    CLA: { selected: false, dropDownIndex: null },
    GLS: { selected: false, dropDownIndex: null },
    "G-Class": { selected: false, dropDownIndex: null },
    "S-Class": { selected: false, dropDownIndex: null },
    EQE: { selected: false, dropDownIndex: null },
    EQS: { selected: false, dropDownIndex: null },
    "AMG GT": { selected: false, dropDownIndex: null },
    EQB: { selected: false, dropDownIndex: null },
  },
  Lincoln: {
    Nautilus: { selected: false, dropDownIndex: null },
    Navigator: { selected: false, dropDownIndex: null },
    "Navigator L": { selected: false, dropDownIndex: null },
    Corsair: { selected: false, dropDownIndex: null },
    Aviator: { selected: false, dropDownIndex: null },
  },
  Volvo: {
    XC60: { selected: false, dropDownIndex: null },
    "XC60 Recharge": { selected: false, dropDownIndex: null },
    XC90: { selected: false, dropDownIndex: null },
    "XC90 Recharge": { selected: false, dropDownIndex: null },
    "V60 Recharge": { selected: false, dropDownIndex: null },
    "V60 Cross Country": { selected: false, dropDownIndex: null },
    S60: { selected: false, dropDownIndex: null },
    "S60 Recharge": { selected: false, dropDownIndex: null },
    "XC40 Recharge Pure Electric": { selected: false, dropDownIndex: null },
    XC40: { selected: false, dropDownIndex: null },
    S90: { selected: false, dropDownIndex: null },
    "S90 Recharge": { selected: false, dropDownIndex: null },
    "C40 Recharge Pure Electric": { selected: false, dropDownIndex: null },
    "V90 Cross Country": { selected: false, dropDownIndex: null },
  },
  Genesis: {
    GV80: { selected: false, dropDownIndex: null },
    G80: { selected: false, dropDownIndex: null },
    GV60: { selected: false, dropDownIndex: null },
    "Electrified GV70": { selected: false, dropDownIndex: null },
    GV70: { selected: false, dropDownIndex: null },
    G90: { selected: false, dropDownIndex: null },
    "Electrified G80": { selected: false, dropDownIndex: null },
    G70: { selected: false, dropDownIndex: null },
  },
  Nissan: {
    LEAF: { selected: false, dropDownIndex: null },
    Pathfinder: { selected: false, dropDownIndex: null },
    "GT-R": { selected: false, dropDownIndex: null },
    Altima: { selected: false, dropDownIndex: null },
    Murano: { selected: false, dropDownIndex: null },
    Versa: { selected: false, dropDownIndex: null },
    Z: { selected: false, dropDownIndex: null },
    Kicks: { selected: false, dropDownIndex: null },
    Armada: { selected: false, dropDownIndex: null },
    Sentra: { selected: false, dropDownIndex: null },
    ARIYA: { selected: false, dropDownIndex: null },
    Rogue: { selected: false, dropDownIndex: null },
  },
  INFINITI: {
    QX80: { selected: false, dropDownIndex: null },
    QX50: { selected: false, dropDownIndex: null },
    QX55: { selected: false, dropDownIndex: null },
    QX60: { selected: false, dropDownIndex: null },
    Q50: { selected: false, dropDownIndex: null },
  },
  Chrysler: {
    "Grand Caravan": { selected: false, dropDownIndex: null },
    Pacifica: { selected: false, dropDownIndex: null },
    "Pacifica Hybrid": { selected: false, dropDownIndex: null },
  },
  Jaguar: {
    "F-TYPE": { selected: false, dropDownIndex: null },
    "I-PACE": { selected: false, dropDownIndex: null },
    "E-PACE": { selected: false, dropDownIndex: null },
    XF: { selected: false, dropDownIndex: null },
    "F-PACE": { selected: false, dropDownIndex: null },
  },
  Jeep: {
    Wrangler: { selected: false, dropDownIndex: null },
    "Wrangler 4xe": { selected: false, dropDownIndex: null },
    "Grand Cherokee 4xe": { selected: false, dropDownIndex: null },
    "Grand Cherokee": { selected: false, dropDownIndex: null },
    "Grand Cherokee L": { selected: false, dropDownIndex: null },
    "Grand Wagoneer": { selected: false, dropDownIndex: null },
    "Grand Wagoneer L": { selected: false, dropDownIndex: null },
    Wagoneer: { selected: false, dropDownIndex: null },
    "Wagoneer L": { selected: false, dropDownIndex: null },
    Compass: { selected: false, dropDownIndex: null },
  },
  Hyundai: {
    Palisade: { selected: false, dropDownIndex: null },
    Kona: { selected: false, dropDownIndex: null },
    "Tucson Plug-In Hybrid": { selected: false, dropDownIndex: null },
    "Tucson Hybrid": { selected: false, dropDownIndex: null },
    Tucson: { selected: false, dropDownIndex: null },
    "IONIQ 6": { selected: false, dropDownIndex: null },
    Elantra: { selected: false, dropDownIndex: null },
    "Elantra Hybrid": { selected: false, dropDownIndex: null },
    Venue: { selected: false, dropDownIndex: null },
    "IONIQ 5": { selected: false, dropDownIndex: null },
    "Kona Electric": { selected: false, dropDownIndex: null },
    Sonata: { selected: false, dropDownIndex: null },
  },
  Porsche: {
    "718 Cayman": { selected: false, dropDownIndex: null },
    Macan: { selected: false, dropDownIndex: null },
    911: { selected: false, dropDownIndex: null },
    "718 Boxster": { selected: false, dropDownIndex: null },
    Taycan: { selected: false, dropDownIndex: null },
    "718 Spyder": { selected: false, dropDownIndex: null },
    Cayenne: { selected: false, dropDownIndex: null },
    Panamera: { selected: false, dropDownIndex: null },
  },
  Polestar: {
    2: { selected: false, dropDownIndex: null },
  },
  Mitsubishi: {
    "Eclipse Cross": { selected: false, dropDownIndex: null },
    Mirage: { selected: false, dropDownIndex: null },
    Outlander: { selected: false, dropDownIndex: null },
    RVR: { selected: false, dropDownIndex: null },
    "Outlander PHEV": { selected: false, dropDownIndex: null },
  },
  FIAT: {
    "500e": { selected: false, dropDownIndex: null },
  },
  Tesla: {
    "Model S": { selected: false, dropDownIndex: null },
    "Model 3": { selected: false, dropDownIndex: null },
    "Model X": { selected: false, dropDownIndex: null },
    "Model Y": { selected: false, dropDownIndex: null },
  },
  VinFast: {
    "VF 8": { selected: false, dropDownIndex: null },
  },
};
