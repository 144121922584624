import "./DashboardHome.scss";
import ButtonGradient from "../ButtonGradient/ButtonGradient";
import dashboardImage from "../../assets/images/laptop-compare.png";
import { Link } from "react-router-dom";
import { useDealershipContext } from "../../contexts/dealershipContext";
import {ReactComponent as MaeCoreLogoYellow} from '../../assets/logos/Mae-Minimized-Logo-Yellow.svg';

function DashboardHome() {
  const { dealershipName, dealershipImgUrl, dealershipId } = useDealershipContext();
  console.log(dealershipImgUrl)

  return (
    <div className="landing_page">
      <div className="product_info">
      <img className="product_info__dealership_logo" src={dealershipImgUrl} alt="" />
        <h1 className="product_info__main_text">Your Customized Car Journey Starts Here</h1>
        <div className="product_info__creator_section">
          <div className="product_info__text" style={{fontWeight : "400"}}>Powered By</div>
          <div className="product_info__logo">
             <MaeCoreLogoYellow />
          </div>
        </div>
        <p className="product_info__text product_info__text--margins"  >
        Welcome to {dealershipName}, we’re excited to be providing you with a 
        customized and seamless car shopping journey. Please take a moment to 
        check-in on this iPad so our team can personalize your experience and 
        help you discover the best of what our dealership has to offer. 
        </p>
        <div className="product_info__button">
          <Link to={`/${dealershipId}/matchmaker-quiz`}>
            <ButtonGradient text="Start Check-in" />
          </Link>
        </div>
      </div>
      <div className="landing_page__filler">
        <img className="landing_page__filler--image"
        src={dashboardImage}
        alt="" />
      </div>
    </div>
  );
}

export default DashboardHome;
