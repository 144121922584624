export const IKIDK_INDEX = 0;
export const IK_VEHICLE_INPUT_INDEX = 1;
export const REASON_NEW_VEHICLE_INDEX = 2;
export const VEHICLE_TYPE_INDEX = 3;
export const VEHICLE_ENGINE_INDEX = 4;
export const EV_SPECIFIC_Q1_INDEX = 5;
export const EV_SPECIFIC_Q2_INDEX = 6;
export const LIFESTYLE_INDEX = 7;
export const FEATURE_SELECT_INDEX = 8;
export const VALUE_RANKING_INDEX = 9;
export const BUDGET_INDEX = 10;
export const NEW_USED_INDEX = 11;
export const PICK_NEW_CARS_INDEX = 12;
export const PICK_USED_CARS_INDEX = 13;
export const TRADE_IN_INDEX = 14;
export const TRADE_IN_INFO_INDEX = 15;
export const PAYMENT_MODE_INDEX = 16;
export const USER_DETAILS_INDEX = 17;
