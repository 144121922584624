import DashboardHome from "../../components/DashboardHome/DashboardHome";
import { useDealershipContext } from "../../contexts/dealershipContext";


function DealershipHome() {

  return (
      <DashboardHome />
  );
}

export default DealershipHome;
