import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useEffect } from "react";

function Home() {
  const navigate = useNavigate();
    
  useEffect(() => {
    navigate('/camclarkford');
  });


  return (
    <LoadingScreen />
  );
}

export default Home;
