// formatUtils.js

export const formatToOneDecimal = (number) => {
  return number.toFixed(1);
};

export const formatPrice = (value) => {
  return new Intl.NumberFormat("en-US").format(value);
};

export const formatToZeroDecimal = (number) => {
  return number.toFixed(0);
};
// formatUtils.js
