import { produce } from "immer";
import { TRADE_IN_INFO_INDEX } from "../../constants/quizQuestionsIndex";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel";

export default function TradeInInfoInput({ quizQuestions, setQuizQuestions }) {
  const questionObj = quizQuestions[TRADE_IN_INFO_INDEX];

  function editTradeInInfo(text) {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const tradeInInfoQuesObj = quizQuestionsDraft[TRADE_IN_INFO_INDEX];
        tradeInInfoQuesObj.tradeInInfo = text;
        tradeInInfoQuesObj.currSelected = [text];
    }));
  }

  return (
    <div className="container">
      <FormFieldWithLabel
          label="Which Vehicle (Year, Make and Model)"
          type="text"
          id="vehicle"
          placeholder=""
          value={questionObj.tradeInInfo}
          onChange={(e) => {
            editTradeInInfo(e.target.value)
          }}
          errorMessage={""}
        />
    </div>
  );
}
