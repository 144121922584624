import './MaeBanner.scss';
import ButtonGradient from '../ButtonGradient/ButtonGradient';
import { Link } from 'react-router-dom';

export default function MaeOfferBanner() {
  return (
    <div className="offer-container">
      <p className="offer-container--text">
        Compare and research cars your way - try the first-ever unbiased and interactive comparison chart to make car researching fun!
      </p>
      <div className="offer-container--button">
        <Link to="https://matchmaker.makingautoeasy.com/">
            <ButtonGradient text="Start Comparing" />
        </Link>
      </div>
    </div>
  );
}