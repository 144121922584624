import "./LikedList.scss";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import EllipsePercentMatch from "../EllipsePercentMatch/EllipsePercentMatch";
import { formatPrice } from "../../utils/formatUtils";
import { getDealershipUserInfo } from "../../services/dealership-user";
import RestartButtonContainer from "../RestartButton/RestartButton";
import discountedIcon from "../../assets/icons/discount.png"
import { useDealershipContext } from "../../contexts/dealershipContext";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ButtonGradient from "../ButtonGradient/ButtonGradient";
import { FEATURE_SELECT_INDEX, PICK_NEW_CARS_INDEX, PICK_USED_CARS_INDEX, VALUE_RANKING_INDEX } from "../../constants/quizQuestionsIndex"
import {ReactComponent as MaeCoreLogoYellow} from '../../assets/logos/Mae-Minimized-Logo-Yellow.svg';
import {ReactComponent as MaeCoreLogoBlack} from '../../assets/logos/MaeCoreLogoBlack.svg'
import NoMatches from "../NoMatches/NoMatches";
import ComparisonChartStatic from "../ComparisonChartStatic/ComparisonChartStatic";

export function MatchSection({ vehicle }) {
  return (
    <div className="liked-card-match">
      {" "}
      <div className="liked-card-match__circle">
        <div className="liked-card-match-coloured-circle">
        <EllipsePercentMatch percentage={vehicle.compatibilityPercentage} />
        </div>
        <div className="liked-card-match-stamp">
          <p className="liked-card-match-stamp__number">
            {vehicle.compatibilityPercentage}
            <span className="liked-card-match-stamp__percent">%</span>
          </p>
          <p className="liked-card-match-stamp__text">match</p>
        </div>
      </div>
      <div className="match__text">
        <div className="match__text--row">
          <p className="text-bold">8/8</p>
          <p className="text-normal">requirements</p>
        </div>
        <div className="match__text--row">
          <p className="text-bold">
            {" "}
            {vehicle.matchedFeatures.length}/{vehicle.totalFeatures.length}
          </p>
          <p className="text-normal">features</p>
        </div>
      </div>
    </div>
  );
}

const UserPrintableProfile = ({ user, dealershipImgUrl, carMatches }) => (
  <div style={{ maxWidth: '600px' }} className="printable">
    <img src={dealershipImgUrl} style={{width:"180px"}}/>
    <div className="product_info__creator_section" style={{alignItems:"center", paddingTop:"0.5rem"}}>
      <h2 style={{margin:"0.5rem 0rem"}}>Customer Profile</h2>
          <div className="product_info__text" style={{fontWeight : "400",width:"auto"}}>Powered By</div>
          <div className="product_info__logo">
             <MaeCoreLogoBlack />
          </div>
    </div>
    <p>First Name: <strong>{user.firstName}</strong></p>
    <p>Last Name: <strong>{user.lastName}</strong></p>
    <p>Email: <strong>{user.email}</strong></p>
    <p>Phone Number: <strong>{user.phoneNo}</strong></p>
    <p>Preferred Method of Contact: <strong>{user.preferredMethodOfContact}</strong></p>
   {user.profile.quiz.map((item, index) => {

      if(index == VALUE_RANKING_INDEX) {
        return null
      }
      let { question, selectedOptions } = item;

      if(index == FEATURE_SELECT_INDEX){
        selectedOptions = user.userSelectedFeatures
      }
      let answers = selectedOptions.length > 0 ? selectedOptions.join(', ') : 'No answers selected';

      if((index === PICK_NEW_CARS_INDEX || index === PICK_USED_CARS_INDEX) & answers !== 'No answers selected') {
        answers = selectedOptions.map(({make,model}) => {return `${make} ${model}`}).join(', ')
      }

      return (
        <div>
        {answers !== 'No answers selected'?<div key={index}>
          <p>
          {question} <strong>{answers}</strong>
          </p>
        </div>
      :null}
      </div>
)})}
  <p>Number of Matches: <strong>{carMatches.length}</strong></p>
  
  {
    carMatches.length !== 0 ? <p>Matched New Vehicles: <strong>
      {carMatches
      .filter((vehicle)=> (vehicle.newOrUsed == "New"))
      .map((vehicle) => (`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim} (${vehicle.stockNumber})`))
      .join(", ")}</strong></p> : null
  }

  {
    carMatches.length !== 0 ? <p>Matched Used Vehicles: <strong>
      {carMatches
      .filter((vehicle)=> (vehicle.newOrUsed == "Used"))
      .map((vehicle) => (`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim} (${vehicle.stockNumber})`))
      .join(", ")}</strong></p> : null
  } 
  </div>
);

function LikedList() {

  const navigate = useNavigate();
  const {dealershipName,dealershipId,linkMatchesToVDP,dealershipImgUrl,defaultInventoryImage} = useDealershipContext();
  const [carMatches, setCarMatches] = useState([]);
  const [zeroMatchesCase, setZeroMatchesCase] = useState(false);
  const [user, setUser] = useState({})
  const [name, setName] = useState("Your");

  const [openedThroughEmail,setOpenedThroughEmail] = useState(null);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setOpenedThroughEmail(params.get("email"));
  },[])

  useEffect(() => {
    const fetchUserInfo = async (id,email) => {
      const res = await getDealershipUserInfo(id,email);
      console.log(res, "res");

      if (res){
        if(res.matches !== 0) {
          setCarMatches(res.carsInfo);
        } else {
          console.log("setting 0 match case true")
          setZeroMatchesCase(true);
        }
        if(res.user) {
          setUser(res.user)
        }
      } else {
        navigate('/404')
      }
     
    };

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    console.log(params, "params", params.get("id"), "id");
    if (carMatches.length <= 0) {
      fetchUserInfo(params.get("id"),params.get("email"));
    }

  }, []);

  if(carMatches.length === 0 && !zeroMatchesCase) {
    return <LoadingScreen />
  }

  // if(zeroMatchesCase) {
  //   return <NoMatches/>
  // }

  const generateUserProfile = (event) => {
    event.preventDefault();
    const printContent = document.getElementById('print');
    const secondprintContent = document.getElementById('second-print');
    const noPrintHeader = document.getElementById('no-print-header');
    const noPrintList = document.getElementById('no-print-list');
    const noPrintNoMatches = document.getElementById('no-print-nomatches')

    if(printContent){
      printContent.style.display = 'block';
    }
    
    if(secondprintContent){
      secondprintContent.style.display = 'none';
    }

    if(noPrintHeader) {
      noPrintHeader.style.display = 'none';
    }
    if (noPrintList) {
      noPrintList.style.display = 'none';
    }
    if(noPrintNoMatches) {
      noPrintNoMatches.style.display = 'none';
    }
    
  };

  const generateChart = (event) => {
    event.preventDefault();
    const printContent = document.getElementById('print');
    const secondprintContent = document.getElementById('second-print');
    const noPrintHeader = document.getElementById('no-print-header');
    const noPrintList = document.getElementById('no-print-list');
    const noPrintNoMatches = document.getElementById('no-print-nomatches')

    if(printContent){
      printContent.style.display = 'none';
    }
    
    if(secondprintContent){
      secondprintContent.style.display = 'block';
    }

    if(noPrintHeader) {
      noPrintHeader.style.display = 'none';
    }
    if (noPrintList) {
      noPrintList.style.display = 'none';
    }
    if(noPrintNoMatches) {
      noPrintNoMatches.style.display = 'none';
    }
    
  };

  const backToMatches = (event) => {
    event.preventDefault();
    const printContent = document.getElementById('print');
    const secondprintContent = document.getElementById('second-print');
    const noPrintHeader = document.getElementById('no-print-header');
    const noPrintList = document.getElementById('no-print-list');
    const noPrintNoMatches = document.getElementById('no-print-nomatches')

    if(printContent){
      printContent.style.display = 'none';
    }
    
    if(secondprintContent){
      secondprintContent.style.display = 'none';
    }
    
    if(noPrintHeader) {
      noPrintHeader.style.display = 'block';
    }
    if (noPrintList) {
      noPrintList.style.display = 'block';
    }
    if(noPrintNoMatches) {
      noPrintNoMatches.style.display = 'block';
    }
    
  };

  return (
    <div className="gallery">
    <div className="gallery__hero" id="no-print-header">
        <div className="gallery__hero--main">
        <img className="product_info__dealership_logo" src={dealershipImgUrl} alt="" />
        <RestartButtonContainer dealershipId={dealershipId}/>
        </div>
        {zeroMatchesCase && openedThroughEmail === "0"? 
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <div style={{width:"15rem", marginTop:"1rem"}}>
          <ButtonGradient text="Print Profile" onClick={generateUserProfile}/> 
          </div> 
        </div>: null}

        {zeroMatchesCase? null :
        <div className="matches_page_heading">
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
          <h1 className ="matches_page_heading--text">{user.firstName}'s Matches</h1>
          <div className=".text" style={{fontWeight : "400",width:"auto", marginLeft:"0.5rem"}}>Powered By</div>
          <div className=".logo" style={{marginLeft:"0.5rem"}}>
              <MaeCoreLogoYellow />
          </div>
          </div>
          {openedThroughEmail === "1"? null : 
          <div style={{display:"flex", justifyContent:"space-evenly"}}> 
          <ButtonGradient text="Print Profile" onClick={generateUserProfile}/> 
          <span style={{width:"1rem"}}/> 
          <ButtonGradient text="Print Chart" onClick={generateChart}/>
          </div>}
        </div> }

        {zeroMatchesCase? null :
        <p>Your profile has been saved! Our Concierge has personalized a list of matches in {dealershipName}’s 
        inventory that best suits your profile - to save you time and hassle. Your Matches are now live and updated daily. 
        Click the link in your inbox any time to come back and see updated and available matches.</p> }
    </div>
    
    {zeroMatchesCase ? <div id="no-print-nomatches"> <NoMatches/>  </div>:  
    <div className="liked-list" id="no-print-list">
      <div className={`liked-list__wrapper`}>
        {carMatches.map((vehicle,index) => {
          return (
            <Link 
            to={linkMatchesToVDP ? vehicle.internalLink: null} 
            key={index} target={linkMatchesToVDP ? "_blank": "_self"}
            onClick={(e) => {
              if (!linkMatchesToVDP) {
                e.preventDefault();
              }
            }}>
            <div className={`liked-card`} key={index}>
                <div className="liked-card__thumbnail">
                  <img
                    className="liked-card__thumbnail--img"
                    src={defaultInventoryImage? defaultInventoryImage:vehicle.imageLink}
                    alt={vehicle.make + vehicle.model}
                  />
                </div>
              <div className="liked-card__details">
              <div className={`liked-card__details--${vehicle.newOrUsed}`}>
                  {`${vehicle.newOrUsed}`}
                </div>
                <div className="liked-card__details--title">
                  {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}
                </div>
                <div className="liked-card__details--price">
                  <div style={{display:"flex",alignItems:"center"}}>
                    ${formatPrice(vehicle.baseMSRP)}
                    {vehicle.discountedFlag?<img className="liked-card__details--discount" alt="" src={discountedIcon}></img>:null}
                  </div>{" "}
                </div>
              </div>
              <MatchSection vehicle={vehicle} />
            </div>
          </Link>
          );
        })}
      </div>
    </div>}
   

    {/* Hidden Print-Only UserProfile component */}
    <div id="print">
      <div style={{display:"flex",justifyContent:"space-between",paddingBottom:"1rem"}}>
      <button style={{background:"None", border:"None",  fontSize:"1rem"}} onClick={backToMatches}>  &#x25c0; Back</button>
      <button style={{background:"None", border:"None",fontSize:"1rem"}} onClick={()=>{window.print()}}>  Print</button>
      </div>
      <UserPrintableProfile user={user} dealershipImgUrl={dealershipImgUrl} carMatches={carMatches}/>
    </div>

    <div id="second-print">
        <div style={{display:"flex",justifyContent:"space-between",paddingBottom:"1rem"}}>
        <button style={{background:"None", border:"None",  fontSize:"1rem"}} onClick={backToMatches}>  &#x25c0; Back</button>
        <button style={{background:"None", border:"None",fontSize:"1rem"}} onClick={()=>{window.print()}}>  Print</button>
        </div>
        <ComparisonChartStatic carsToCompare={carMatches} name={user.firstName}/>
    </div>

  </div>
  
  );
}

export default LikedList;
